<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="product-ban-container">
                <div class="img"><img src="/images/products/product-ban-koong.jpg" alt="쿵마켓 특가!"></div>
            </div>
			<div class="container">
				<div class="product-list-container">
					<!-- 상품 리스트 -->
					<section class="product-lists">
						<div class="product-lists__head">
							<div class="product-lists__order">
								<div class="product-lists__group">
									<ul class="product-lists__group-lists">
										<li><a class="on" href="">높은 가격순</a></li>
										<li><a href="">낮은 가격순</a></li>
									</ul>
								</div>
								<div class="product-lists__select">
									<select class="product-lists__select-option" name="" id="">
										<option value="">20개 보기</option>
									</select>
								</div>
							</div>
						</div>
						<div class="product-lists__body">
							<div class="grid grid--col-4">
								<div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div><div class="grid-6">
									<a class="product-list" href="/shop/products/view">
										<span class="product-list__thumb"><img class="product-list__thumbnail" src="/images/sample/product-thumbnail-medium.jpg" alt=""></span>
										<span class="product-list__title">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</span>
										<span class="product-list__price">
											<span class="product-list__price-left">
												<em class="product-list__price-num">15,000</em>원
												<span class="product-list__price-percent">25%</span>
											</span>
										</span>
									</a>
								</div>
							</div>

							<div class="product-lists__pagination">
								<div class="pagination">
									<a class="pagination__backward" href=""></a>
									<a class="pagination__prev" href=""></a>
									<a class="current">1</a>
									<a class="" href="">2</a>
									<a class="pagination__next" href=""></a>
									<a class="pagination__forward" href=""></a>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
		console.log("koong-market");
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
